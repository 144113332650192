<template>
  <div>
    <v-row class="mt-3">
      <v-col md="12">
        <v-alert border="top" color="green lighten-2" dark>
          <h4>{{ $t("قايمة المدفوعات") }}</h4>
        </v-alert>
      </v-col>
    </v-row>

    <!-- Start Filters -->
    <div>
      <v-row>
        <v-col :md="2">
          <div>
            <label>{{ $t("Name") }}</label>
            <v-text-field
                class="d-block my-2"
                type="text"
                name="customer_name"
                hide-details
                dense
                outlined
                v-model="filters.customer_name"
                @input="updateParam($event, 'customer_name')"
            ></v-text-field>
          </div>
        </v-col>
        <v-col :md="2">
          <div>
            <label>{{ $t("Mobile") }}</label>
            <v-text-field
                class="d-block my-2"
                type="text"
                name="customer_name"
                hide-details
                dense
                outlined
                @input="updateParam($event, 'customer_mobile')"
                v-model="filters.customer_mobile"
            ></v-text-field>
          </div>
        </v-col>
        <v-col :md="1">
          <div>
            <label>{{ $t("رقم الإشتراك") }}</label>
            <v-text-field
                class="d-block my-2"
                type="text"
                name="subscription_id"
                hide-details
                dense
                outlined
                @input="updateParam($event, 'subscription_id')"
                v-model="filters.subscription_id"
            ></v-text-field>
          </div>
        </v-col>
        <v-col :md="2">
          <div>
            <label>{{ $t("StartDate") }}</label>
            <el-date-picker
                style="width: 100%"
                type="date"
                class="d-block my-2"
                name="start_date"
                hide-details
                dense
                outlined
                @input="updateParam($event, 'start_date')"
                v-model="filters.start_date"
            >
            </el-date-picker>
          </div>
        </v-col>
        <v-col :md="2">
          <div>
            <label>{{ $t("EndDate") }}</label>
            <el-date-picker
                style="width: 100%"
                type="date"
                class="d-block my-2"
                name="start_date"
                hide-details
                dense
                outlined
                @input="updateParam($event, 'end_date')"
                v-model="filters.end_date"
            >
            </el-date-picker>
          </div>
        </v-col>

        <v-col :md="1" class="mt-6">
          <v-btn
              color="third"
              hide-details
              @click="getDataFromApi"
              style="display: block; margin-top: 10px"
              class="w-full h-btn white-clr"
              small
          >
            {{ $t("Filter") }}
          </v-btn>
        </v-col>
        <v-col :md="1" class="mt-6">
          <v-btn
              color="danger"
              hide-details
              @click="clearFilters"
              style="display: block; margin-top: 10px"
              class="w-full h-btn white-clr"
              small
          >
            {{ $t("Clear") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->
    <!-- Start DataTable -->
    <v-data-table
        :headers="table.headers"
        :items="table.items"
        :loading="table.loading"
        :items-per-page="table.itemsPerPage"
        :page.sync="table.page"
        hide-default-footer
        class="elevation-1"
        :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

    </v-data-table>
    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div class="text-center pt-2">
      <v-pagination
          v-if="table.length"
          total-visible="5"
          v-model="table.page"
          :length="table.length"
          @input="changePage"
      ></v-pagination>
    </div>

    <!-- End Pagination -->
  </div>
</template>
<script>

export default {
  name: "transactions",
  data() {
    return {

      filters: {
        customer_name: this.$route.query.customer_name || "",
        customer_mobile: this.$route.query.customer_mobile || "",
        subscription_id: this.$route.query.subscription_id || "",
        start_date: this.$route.query.start_date || "",
        end_date: this.$route.query.end_date || "",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        itemExport: {
          "#": "id",
          "العميل": {
            field: "customer_name",
            callback: (item) => {
              if (item) return item;
            },
          },
          "الموبايل": {
            field: "customer_mobile",
            callback: (item) => {
              if (item) return item;
            },
          },
          "رقم الإشتراك": {
            field: "city_name",
            callback: (item) => {
              if (item) return item;
            },
          },
          "التاريخ": {
            field: "from_date",
            callback: (item) => {
              if (item) return item;
            },
          },

        },
        headers: [
          { text: "#", value: "id", width: "5rem" },
          { text: this.$t("CustomerName"), value: "customer_name", width: "8rem",},
          { text: this.$t("Mobile"), value: "customer_phone", width: "7rem",},
          { text: this.$t("رقم الإشتراك"), value: "subscription_id", width: "7rem",},
          { text: this.$t("رقم العملية"), value: "transaction_id", width: "7rem",},
          { text: this.$t("السعر الإجمالي"), value: "amount", width: "8rem",},
          { text: this.$t("تاريخ العملية"), value: "parse_created_at", width: "7rem",},
        ],
      },
    }
  },
  created() {

    this.getDataFromApi();
  },
  methods: {
    changePage(page) {
      this.table.page = page; // Update the page in the data table object
      // You can perform any additional actions here if needed
    },
    getDataFromApi(){
      this.table.items = [];
      this.table.loading = true;
      this.$store.dispatch(`transaction/list`).then((res) => {
        this.table.items = res.data
        this.table.length = Math.ceil(res.data.length / this.table.itemsPerPage);
      }).finally(() => {
        this.table.loading = false;
      });
    },

    updateParam(value, key) {
      const query = { ...this.$route.query, [key]: value };
      const route = { ...this.$route, query };
      this.$router.replace(route);
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
      this.getDataFromApi();
    },
  },
}


</script>